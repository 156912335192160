import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Grid from '@material-ui/core/Grid';
import AgencySelect from '../../form/components/specialized/AgencySelect';
import EmployeeSelect from '../../form/components/specialized/EmployeeSelect';
import { useFieldFast } from '../../form/hooks';

const AgencyEmployeeSelect = ({ name, agencyFieldName, label, agencyLabel, fullWidth, disabled }) => {
    const [{ value: agencyId }, { initialValue: initialAgencyId }] = useField(agencyFieldName);
    const [, { initialValue: initialEmployeeId }, { setValue: setEmployeeId }] = useFieldFast(name);

    const handleAgency = useCallback(
        (field, value) => {
            setEmployeeId(value === initialAgencyId ? initialEmployeeId : '');
        },
        [setEmployeeId, initialAgencyId, initialEmployeeId]
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <AgencySelect
                    name={agencyFieldName}
                    label={agencyLabel}
                    onChange={handleAgency}
                    fullWidth={fullWidth}
                />
            </Grid>
            <Grid item xs={6}>
                <EmployeeSelect
                    name={name}
                    label={label}
                    agencyId={agencyId}
                    fullWidth={fullWidth}
                    disabled={!agencyId}
                />
            </Grid>
        </Grid>
    );
};

AgencyEmployeeSelect.propTypes = {
    name: PropTypes.string.isRequired,
    agencyFieldName: PropTypes.string.isRequired,
    label: PropTypes.string,
    agencyLabel: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
};

AgencyEmployeeSelect.defaultProps = {
    label: null,
    agencyLabel: null,
    fullWidth: false,
    disabled: false,
};

export default AgencyEmployeeSelect;
