import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Grid } from '@material-ui/core';
import Form from '../../form/components/Form';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
import SubmitButton from '../../form/components/SubmitButton';
import TitleBar from '../../layout/components/TitleBar';
import Typography from '../../mui/Components/Typography';
import ToggleButtons from '../../form/components/ToggleButtons';
import TextInput from '../../form/components/TextInput';
import ConditionalField from '../../form/components/specialized/ConditionalField';
import DateInput from '../../form/components/DateInput';
import Checkbox from '../../form/components/Checkbox';
import CheckInOutDisable from './CheckInOutDisable';
import { useCustomer } from '../../customers/customersSlice';
import {
    showNurseDeployment,
    storeNurseDeployment,
    updateNurseDeployment,
    useNurseDeployment,
} from '../nurseDeploymentsSlice';
import NursePriceFieldView from './NursePriceFieldView';
import A1DocumentList from '../../a1document/components/A1DocumentList';
import ContractSelect from '../../contracts/components/ContractSelect';
import CareNeedSelect from '../../careNeeds/components/CareNeedSelect';
import { destroyA1Document, storeA1Document } from '../../a1document/a1DocumentsSlice';

import { NURSE_DEPLOYMENT_RESOURCE } from '../../api/resources';
import { useCareNeedsByCustomerId } from '../../careNeeds/careNeedsSlice';
import { useInitialValues } from '../../form/hooks';
import { nurseDeploymentSchema } from '../schema';
import NurseAutocomplete from '../../form/components/specialized/autocomplete/NurseAutocomplete';
import AgencyByContractSelect from './AgencyByContractSelect';
import PauseReasonSelect from './PauseReasonSelect';
import { useUser } from '../../auth/hooks';
import { selectAllAgencies } from '../../agencies/agenciesSlice';
import { useMakeSelector } from '../../store/hooks';
import {
    makeContractsByCustomerIdSelector,
} from '../../contracts/contractsSlice';

import { selectTransferByDeploymentId } from '../../nurseTransfers/selectors';
import { TRANSFER_TYPE_ARRIVAL, TRANSFER_TYPE_DEPARTURE } from '../../nurseTransfers/types';
import { selectUserIsRoot } from '../../../Modules/auth/selectors';
import { useApi } from '../../api/components/ApiProvider';
import { EMAIL_RESOURCE } from '../../api/resources';
import { formatDate, parseDate, addValueToString } from '../../../helpers/tools';

const DEFAULT_DEPLOYMENT_TYPES = [
    { value: 'nurse', label: 'BK', disabled: false },
    { value: 'pause', label: 'Pause', disabled: false },
    { value: 'pause_fee_based', label: 'Pause (Kostenpflichtig)', disabled: true },
    { value: 'cancellation_period', label: 'Kündigungsfrist', disabled: false },
];

const checkForCommissionRelevantClosedData = (values, customerId, isRoot, isActiveCommissionRelevantDataChange, changeDataInfo, email_api) => {
    // commission relevant data is closed from the beginning of the 27th of the month until the end of the current month
    const today = new Date();
    const dt_billing_till = parseDate(values.billingTill);

    // calculate the date 1 month before the billing till date
    if (dt_billing_till >= today)
    {
        // billing still in the future
        return;
    }

    // billing in the past
    const trigger_date = new Date(today);
    trigger_date.setMonth(trigger_date.getMonth() - 1);
    trigger_date.setDate(27);
    //console.log("trigger_date=>",trigger_date," billing till=>",dt_billing_till);

    //isRoot = false;

    const isSetComissionDataClosed = (trigger_date < today) && (isRoot === false);
    //console.log(isSetComissionDataClosed, isActiveCommissionRelevantDataChange);

    // send a report mail
    if (isSetComissionDataClosed && isActiveCommissionRelevantDataChange) {
        // send a report mail
        const email_text = `Liebe/r Mitarbeiter/in, <br /> <br />\
        Die BK-Daten des Kunden #${customerId} wurden nach bereits abgeschlossener Provisions-Abrechnung geändert.<br /> <br />\
        ${changeDataInfo}<br /><br />\
        *** Dies ist eine automatisch generierte E-Mail und dient zur schnelleren Kommunikation. ***<br /> <br />`;

        const preppedValues = {
            from: 'buchhaltung@pflegezuhause.info',
            subject: `#${customerId} Änderung BK-Verlauf nach abgeschlossener Provisions-Abrechnung `,
            text: email_text,
            receiver: ['buchhaltung@pflegezuhause.info'],
            attachments: [],
            customer_id: customerId,
            is_premium: false,
        };
        //console.log("send commission data closed => request.id / preppedValues =>",customerId, preppedValues);
        if (process.env.REACT_APP_ENV !== 'development')
        {
            email_api[EMAIL_RESOURCE].sendEmail(preppedValues);
        }
        else console.log("development: send commission dat",preppedValues);
    }

    return false;
};

const submitFull = async (
    values,
    deleteA1Docs,
    nurseDeploymentId,
    customerId,
    employeeId,
    copy,
    dispatch,
    isRoot,
    isActiveCommissionRelevantDataChange,
    email_api
) => {
    const prepped = { ...values, id: copy ? null : nurseDeploymentId, customerId };

    if (!copy) {
        await Promise.all(
            deleteA1Docs.map((id) => {
                return dispatch(destroyA1Document({ id }));
            })
        );
    }

    const result = await dispatch(
        nurseDeploymentId && !copy ? updateNurseDeployment(prepped) : storeNurseDeployment(prepped)
    );
    console.log("submitFull=>",values.billingFrom, values.billingTill);
    if (values.billingFrom !== null && values.billingTill !== null)
        checkForCommissionRelevantClosedData(
            values,
            customerId,
            isRoot,
            isActiveCommissionRelevantDataChange,
            email_api
        );

    const { a1From, a1Till } = values;
    if (a1From && a1Till) {
        await dispatch(
            storeA1Document({
                nurseDeploymentId: result.data.id,
                dateFrom: a1From,
                dateTill: a1Till,
                acceptedManuallyAt: new Date(),
                acceptedManuallyBy: employeeId,
            })
        );

        /* a1State has probably changed -> reload nurse deployment...
         * TODO: should this be handled by sockets automatically?
         */
        return dispatch(showNurseDeployment({ id: result.data.id }));
    }

    return result;
};

const NurseDeploymentForm = ({
    careNeedId,
    nurseDeploymentId,
    customerId: customerIdOverride,
    copy,
    onDone,
    submitRef,
}) => {
    const dispatch = useDispatch();
    const [isActiveCommissionRelevantDataChange, setIsActiveCommissionRelevantDataChange] = useState(false);
    const [changeDataInfo, setChangeDataInfo] = useState('');
    const [lastDeploymentType, setLastDeploymentType] = useState(undefined);

    const [optionsDeploymentTypes, setOptionsDeploymentTypes] = useState(DEFAULT_DEPLOYMENT_TYPES);
    const [deleteA1DocList, setDeleteA1DocList] = useState([]);
    const [nurseDeployment] = useNurseDeployment(nurseDeploymentId);
    const deploymentArrivalAt = useSelector((state) =>
        selectTransferByDeploymentId(state, nurseDeploymentId, TRANSFER_TYPE_ARRIVAL)
    );
    const deploymentDepartureAt = useSelector((state) =>
        selectTransferByDeploymentId(state, nurseDeploymentId, TRANSFER_TYPE_DEPARTURE)
    );

    const customerId = customerIdOverride || (nurseDeployment && nurseDeployment.customerId);
    const customer = useCustomer(customerId);
    const careNeeds = useCareNeedsByCustomerId(customerId);
    const user = useUser();
    const agencies = useSelector(selectAllAgencies);
    const contracts = useMakeSelector(makeContractsByCustomerIdSelector, customerId);
    const isRoot = useSelector(selectUserIsRoot);
    const email_api = useApi();

    const initialValues = useInitialValues(nurseDeployment, {
        careNeedId: (careNeeds.length === 1 && careNeeds[0].id) || careNeedId || '',
        customerContractId: '',
        customerContractEndAt: '',
        agencyId: '',
        type: 'nurse',
        nurseId: '',
        careFrom: null,
        careTill: null,
        notes: '',
        arrivalAt: null,
        departureAt: null,
        withoutArrTransfer: false,
        withoutDepTransfer: false,
        a1From: null,
        a1Till: null,
        billingFrom: null,
        billingFromHalf: false,
        billingTill: null,
        billingTillHalf: false,
        priceAgency: '',
        priceAgencyDaily: false,
        surchargeAgency: '',
        a1Notes: '',
        provisionPZH: '',
        surchargePZH: '',
        provisionLocation: '',
        provisionMultiplier: '', // FIXME: missing in form
        pauseReason: '',
    });

    const handleSubmit = useCallback(
        (values) => {
            submitFull(
                values,
                deleteA1DocList,
                nurseDeploymentId,
                customerId,
                user.employeeId,
                copy,
                dispatch,
                isRoot,
                isActiveCommissionRelevantDataChange,
                changeDataInfo,
                email_api
            ).then(onDone);
        },
        [
            dispatch,
            isRoot,
            isActiveCommissionRelevantDataChange,
            changeDataInfo,
            nurseDeploymentId,
            customerId,
            copy,
            deleteA1DocList,
            onDone,
            user.employeeId,
            email_api,
        ]
    );

    const handleDisableBreakFeeBased = () => {
        //console.log('handleDisableBreakFeeBased');
        setOptionsDeploymentTypes((prevOptions) =>
            prevOptions.map((option) =>
                option.value === 'pause_fee_based' ? { ...option, disabled: true } : option
            )
        );
    };

    const handleEnableBreakFeeBased = () => {
        //console.log('handleEnableBreakFeeBased');
        setOptionsDeploymentTypes((prevOptions) =>
            prevOptions.map((option) =>
                option.value === 'pause_fee_based' ? { ...option, disabled: false } : option
            )
        );
    };

    const updateBillingDates = useCallback(
        (values, setfieldvalue) => {
            //console.log('updateBillingDates=>', values.type,deploymentArrivalAt?.date, deploymentDepartureAt?.date);
            if (values.type === 'nurse') {
                // get's updated at the TransferForm
            } else if (values.type === 'pause' || values.type === 'pause_fee_based') {
                setfieldvalue('billingFrom', values.careFrom);
                setfieldvalue('billingTill', values.careTill);
            } else if (values.type === 'cancellation_period') {
                setfieldvalue('billingFrom', values.careFrom);
                setfieldvalue('billingTill', values.careTill);
            }
        },
        []
    );

    const updateContractInformation = useCallback((customerContract, setfieldvalue) => {
        setfieldvalue('customerContractEndAt', customerContract.endAt);
    }, []);

    /**
     * clear the current calculated and rendered fees
     * @param {object} setfieldvalue 
     */
    const clearBreakFee = useCallback(
        (setfieldvalue) => {
            setfieldvalue('fixedAgencyPrice', 0.0);
            setfieldvalue('priceAgency', 0.0);
            setfieldvalue('provisionPZH', 0.0);
            setfieldvalue('provisionLocation', 0.0);
            setfieldvalue('surchargeAgency', 0.0);
            setfieldvalue('surchargePZH', 0.0);
    }, []);

    const updateBreakFee = useCallback(
        (agency, setfieldvalue) => {
            if (agency === undefined) return;

            const locationId = customer[0]?.locationId;
            const hamburgId = parseInt(process.env.REACT_APP_LOCATION_HAMBURG_ID);

            const defaultCost = agency.defaultCostFeeBasedBrake;
            if (defaultCost === undefined || defaultCost === null || defaultCost <= 0.0) return;

            const fixPriceAgency = defaultCost / 2;
            const fixProvisionPZH = fixPriceAgency; // locationId !== hamburgId ? fixPriceAgency / 2 : fixPriceAgency
            const fixProvisionLocation = locationId !== hamburgId ? fixPriceAgency / 2 : '';
            setfieldvalue('fixedAgencyPrice', defaultCost);
            setfieldvalue('priceAgency', fixPriceAgency);
            setfieldvalue('provisionPZH', fixProvisionPZH);
            setfieldvalue('provisionLocation', fixProvisionLocation);
        },
        [customer]
    );

    const isCommissionRelevantDataChange = (newvalues, initvalues) => {
        let changes = '';
        let is_changed = false;

        //console.log("isCommissionRelevantDataChange=>",newvalues, initvalues);

        if (newvalues.provisionPZH !== initvalues.provisionPZH) {
            //console.log("not identical => provisionPZH",newvalues.provisionPZH,initvalues.provisionPZH);
            changes = addValueToString(changes, `Provision PZH => ${newvalues.provisionPZH}`);
            is_changed = true;
        }
        if (newvalues.surchargePZH !== initvalues.surchargePZH) {
            //console.log("not identical => surchargePZH",newvalues.surchargePZH ,initvalues.surchargePZH);
            changes = addValueToString(changes, `Zuschlag PZH => ${newvalues.surchargePZH}`);
            is_changed = true;
        }
        if (newvalues.provisionLocation !== initvalues.provisionLocation) {
            //console.log("not identical => provisionLocation",newvalues.provisionLocation ,initvalues.provisionLocation);
            changes = addValueToString(
                changes,
                `Provision Standort => ${newvalues.provisionLocation}`
            );
            is_changed = true;
        }
        if (
            newvalues.billingFrom !== 'Invalid date' &&
            areDateStringIdentical(newvalues.billingFrom, initvalues.billingFrom) === false
        ) {
            //console.log("not identical => billingFrom",newvalues.billingFrom, initvalues.billingFrom);
            changes = addValueToString(changes, `Berechnung vom => ${newvalues.billingFrom}`);
            is_changed = true;
        }
        if (
            newvalues.billingTill !== 'Invalid date' &&
            areDateStringIdentical(newvalues.billingTill, initvalues.billingTill) === false
        ) {
            //console.log("not identical => billingTill",newvalues.billingTill, initvalues.billingTill);
            changes = addValueToString(changes, `Berechnung bis => ${newvalues.billingTill}`);
            is_changed = true;
        }

        if (is_changed) {
            const topdata = `Betreuung von: ${newvalues.careFrom} Betreuung bis: ${newvalues.careTill}<br />\
                             Berechnung von: ${newvalues.billingFrom} Berechnung bis: ${newvalues.billingTill}`;

            changes = `${topdata}:<br /><br />Folgende Daten wurden geändert:<br />${changes}`;
        }

        return { is_changed, changes };
    };

    const areDateStringIdentical = (date1, date2) => {
        if (date1 === date2) return true;
        const date_only_1 = formatDate(date1);
        const date_only_2 = formatDate(date2);
        return date_only_1 === date_only_2;
    }

    /**
     * make some ui-updates based on the changed deployment type
     * @param {string} newDeploymentType
     * @param {object} setfieldvalue
     * @param {object} values
     */
    const handleOnChangeDeploymentType = (newDeploymentType, setfieldvalue, values) => {
        if (lastDeploymentType !== newDeploymentType) {
            //console.log(`deployment type changed ${lastDeploymentType} => ${newDeploymentType}`);

            if (newDeploymentType !== 'pause_fee_based') {
                setfieldvalue('fixedAgencyPrice', undefined);
            }

            if (lastDeploymentType === 'pause_fee_based') {
                // we change to another type => remove the last calculation
                clearBreakFee(setfieldvalue);
            }

            if (newDeploymentType === 'pause_fee_based' && values.agencyId !== undefined) {
                if (!agencies) return;
                const agency = agencies.find((agency) => agency.id === values.agencyId);
                updateBreakFee(agency, setfieldvalue);
            }
            updateBillingDates(values, setfieldvalue);

            setLastDeploymentType(newDeploymentType);
        }
    };

    const handleOnChange = (newvalues, initvalues, setfieldvalue) => {
        //console.log('newvalues=>', newvalues.type, 'initvalues=>', initialValues.type);
        handleOnChangeDeploymentType(newvalues.type, setfieldvalue, newvalues);

        const result = isCommissionRelevantDataChange(newvalues, initvalues);
        setIsActiveCommissionRelevantDataChange(result.is_changed);
        setChangeDataInfo(result.changes);
        //console.log("isActiveCommissionRelevantDataChange =>",isActiveCommissionRelevantDataChange);
        if (result.is_changed) {
            //console.log("changes=>","new=>", newvalues,"old=>", initvalues);
        }

        //updateBillingDates(newvalues, setfieldvalue);

        if (newvalues.customerContractId !== null && newvalues.customerContractId !== '') {
            const customerContract = contracts.find(
                (contract) => contract.id === newvalues.customerContractId
            );
            updateContractInformation(customerContract, setfieldvalue);
        }

        if (
            newvalues.agencyId !== undefined &&
            newvalues.agencyId !== '' &&
            newvalues.agencyId !== null
        ) {
            if (!agencies) return;
            const agency = agencies.find((agency) => agency.id === newvalues.agencyId);
            const defaultCost = agency.defaultCostFeeBasedBrake;
            if (defaultCost === undefined || defaultCost === null || defaultCost <= 0.0)
                handleDisableBreakFeeBased();
            else handleEnableBreakFeeBased();
        } else {
            handleDisableBreakFeeBased();
        }
    };

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            subject={nurseDeployment || NURSE_DEPLOYMENT_RESOURCE}
            validationSchema={nurseDeploymentSchema}
            onChange={handleOnChange}
        >
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <ToggleButtons
                        name="type"
                        options={optionsDeploymentTypes}
                        exclusive
                    />
                </Grid>
                {careNeeds.length > 1 && (
                    <Grid item xs={3}>
                        <CareNeedSelect
                            label="BK-Bedarf"
                            name="careNeedId"
                            customerId={customerId}
                        />
                    </Grid>
                )}
                <Grid item xs={6}>
                    <ContractSelect
                        label="Vertrag"
                        name="customerContractId"
                        customerId={customerId}
                    />
                    <AgencyByContractSelect name="agencyId" contractName="customerContractId" />
                    <Box component="span" display="none">
                            <DateInput
                                name="customerContractEndAt"
                            />
                        <TextInput
                            name="fixedAgencyPrice"
                            label="Pause Fest-Preis Agentur"
                            type="number"
                            money
                            fullWidth
                        />
                    </Box>
                </Grid>

                <ConditionalField
                    conditionFieldName="type"
                    condition={(value) => value === 'nurse'}
                >
                    <Grid item xs={6}>
                        <NurseAutocomplete name="nurseId" label="Betreuungskraft" fullWidth />
                    </Grid>
                </ConditionalField>

                <ConditionalField
                    conditionFieldName="type"
                    condition={(value) => value === 'pause' || value === 'pause_fee_based'}
                >
                    <Grid item xs={6}>
                        <PauseReasonSelect name="pauseReason" label="Pausengrund" fullWidth />
                    </Grid>
                </ConditionalField>


                <Grid item xs={12}>
                    <Box mt={5}>
                        <TitleBar>Einsatz</TitleBar>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <DateInput name="careFrom" label="Betreuung von" fullWidth />
                </Grid>
                <Grid item xs={3}>
                    <DateInput name="careTill" label="Betreuung bis" fullWidth />
                </Grid>
                <Grid item xs={6} />
                <CheckInOutDisable
                    nameArr="withoutArrTransfer"
                    labelArr="Anreise nicht erforderlich"
                    nameDep="withoutDepTransfer"
                    labelDep="Abreise nicht erforderlich"
                    arrivalAt={deploymentArrivalAt}
                    departureAt={deploymentDepartureAt}
                    xs={3}
                />

                <Grid item xs={12}>
                    <Box mt={5}>
                        <TitleBar>Kosten</TitleBar>
                    </Box>
                </Grid>

                <Grid item xs={3}>
                    <TextInput
                        name="priceAgency"
                        label="Preis Agentur"
                        type="number"
                        money
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextInput
                        name="surchargeAgency"
                        label="Zuschlag Agentur"
                        type="number"
                        money
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextInput
                        name="provisionPZH"
                        label="Provision PZH"
                        type="number"
                        money
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextInput
                        name="surchargePZH"
                        label="Zuschlag PZH"
                        type="number"
                        money
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <ToggleButtons
                        name="priceAgencyDaily"
                        options={[
                            { value: true, label: 'pro Tag' },
                            { value: false, label: 'pro Monat' },
                        ]}
                        exclusive
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography>Endpreis Kunde</Typography>
                    <NursePriceFieldView />
                </Grid>
                <Grid item xs={3}>
                    <TextInput
                        name="provisionLocation"
                        label={
                            customer.multiplierId
                                ? 'davon Provi. Pflegedienst-Partner'
                                : 'davon Provi. Standort'
                        }
                        type="number"
                        money
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={3}>
                    <DateInput name="billingFrom" label="Berechnung vom" fullWidth />
                    <Checkbox name="billingFromHalf" label="Anreise halber Tag" />
                </Grid>
                <Grid item xs={3}>
                    <DateInput name="billingTill" label="Berechnung bis" fullWidth />
                    <Checkbox name="billingTillHalf" label="Abreise halber Tag" />
                </Grid>

                <Grid item xs={12}>
                    <Box mt={5}>
                        <TitleBar>Für folgenden Zeitraum wurden A1-Bescheinigungen nachgewiesen</TitleBar>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <DateInput name="a1From" label="A1 vom" fullWidth />
                </Grid>
                <Grid item xs={3}>
                    <DateInput name="a1Till" label="A1 bis" fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        name="a1Notes"
                        label="A1 Bemerkungen"
                        rows={1}
                        rowsMax={6}
                        multiline
                        fullWidth
                    />
                </Grid>
                {nurseDeploymentId && (
                    <Grid item xs={6}>
                        <A1DocumentList
                            nurseDeploymentId={nurseDeploymentId}
                            onChange={(list) => setDeleteA1DocList(list)}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Box mt={5} mb={1}>
                        <TitleBar>Notizen</TitleBar>
                    </Box>
                    <TextInput
                        name="notes"
                        label="Notizen"
                        rows={4}
                        rowsMax={10}
                        multiline
                        fullWidth
                    />
                </Grid>
            </Grid>
            <SubmitButton container={submitRef} />
        </Form>
    );
};

NurseDeploymentForm.propTypes = {
    customerId: IdPropType,
    careNeedId: IdPropType,
    nurseDeploymentId: IdPropType,
    copy: PropTypes.bool,
    onDone: PropTypes.func.isRequired,
    submitRef: RefPropType,
};

NurseDeploymentForm.defaultProps = {
    customerId: null,
    careNeedId: null,
    nurseDeploymentId: null,
    copy: false,
    submitRef: null,
};

export default NurseDeploymentForm;
